import React from 'react';
import { Link } from "gatsby";

import Layout from '../components/layout';
import SEO from "../components/seo";

import './sitemap.scss';

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <article className={`${pageContext.slug} sitemap-template`}>
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h2>Site Map</h2>
                    <div className="just-padding">
                        <div className="list-group list-group-root well">
                            <Link to="/" className="list-group-item">Home</Link>
                            <a to="/#about" className="list-group-item">About</a>
                            <Link to="/contact-us/" className="list-group-item">Contact Us</Link>
                            <a href="/services/" className="list-group-item">Services</a>
                            <div className="list-group">
                                <Link to="/services/advertising/" className="list-group-item">
                                    Advertising
                                </Link>
                                <Link to="/services/interactive/" className="list-group-item">
                                    Interactive
                                </Link>
                                <Link to="/services/public-relations/" className="list-group-item">
                                    Public Relations
                                </Link>
                                <Link to="/services/social-media/" className="list-group-item">
                                    Social Media
                                </Link>
                                <Link to="/services/research/" className="list-group-item">
                                    Research
                                </Link>
                            </div>
                            <div className="list-group-item bold">
                                <Link to="/case-studies/">Case Studies</Link>
                            </div>
                            <div className="list-group">
                                <Link to="/case-studies/anywhereapp/" className="list-group-item">AnywhereApp</Link>
                                <Link to="/case-studies/audl-pr/" className="list-group-item">AUDL PR</Link>
                                <Link to="/case-studies/boca-jets-lacrosse/" className="list-group-item">Boca Jets Lacrosse</Link>
                                <Link to="/case-studies/boca-raton-historical-society/" className="list-group-item">Boca Raton Historical Society</Link>
                                <Link to="/case-studies/capgemini/" className="list-group-item">Capgemini</Link>
                                <Link to="/case-studies/citrix/" className="list-group-item">Citrix</Link>
                                <Link to="/case-studies/citrix-and-microsoft/" className="list-group-item">Citrix and Microsoft</Link>
                                <Link to="/case-studies/citrix-chromeos-vertical-marketing/" className="list-group-item">Citrix ChromeOS Vertical Marketing</Link>
                                <Link to="/case-studies/citrix-google-earned-media/" className="list-group-item">Citrix Google Earned Media</Link>
                                <Link to="/case-studies/dxc/" className="list-group-item">DXC</Link>
                                <Link to="/case-studies/equifax/" className="list-group-item">Equifax</Link>
                                <Link to="/case-studies/finastra-microsoft/" className="list-group-item">Finastra and Microsoft</Link>
                                <Link to="/case-studies/fujitsu-and-microsoft/" className="list-group-item">Fujitsu and Microsoft</Link>
                                <Link to="/case-studies/genzeon/" className="list-group-item">Genzeon</Link>
                                <Link to="/case-studies/black-book/" className="list-group-item">Hearst</Link>
                                <Link to="/case-studies/hpe/" className="list-group-item">HPE</Link>
                                <Link to="/case-studies/insight-and-microsoft/" className="list-group-item">Insight and Microsoft</Link>
                                <Link to="/case-studies/kddiamerica/" className="list-group-item">KDDIAmerica</Link>
                                <Link to="/case-studies/lifesync/" className="list-group-item">LifeSync</Link>
                                <Link to="/case-studies/mdlive/" className="list-group-item">MDLIVE</Link>                                
                                <Link to="/case-studies/microsoft-business-applications/" className="list-group-item">Microsoft Business Applications</Link>
                                <Link to="/case-studies/microsoft-nuance/" className="list-group-item">Microsoft + Nuance</Link>
                                <Link to="/case-studies/quackskins/" className="list-group-item">Quackskins</Link>
                                <Link to="/case-studies/softnas/" className="list-group-item">SoftNAS</Link>
                                <Link to="/case-studies/swapalease/" className="list-group-item">Swapalease</Link>
                                <Link to="/case-studies/teladoc-health-microsoft/" className="list-group-item">Teladoc Health + Microsoft</Link>
                                <Link to="/case-studies/fleet-advantage/" className="list-group-item">Fleet Advantage</Link>
                            </div>
                            <div className="list-group-item divlink">Reel</div>
                            <a className="list-group-item" href="/#clients">Clients</a>
                            <Link to="/pr-agency-florida/" className="list-group-item">PR Agency Florida</Link>
                            <Link to="/account-based-marketing/" className="list-group-item">Account Based Marketing</Link>
                            <Link to="/bocajetslacrosse/" className="list-group-item">Boca Jets Lacrosse</Link>
                            <Link to="/news/" className="list-group-item">News</Link>
                            <Link to="/cpaas/" className="list-group-item">2021-2022 Merit Mile CPaaS Report</Link>  
                            <Link to="/kddi-america/" className="list-group-item">KDDI America Case Study Download</Link>
                            <Link to="/marketing-skills/" className="list-group-item">Top 10 Hard and Soft Skills Marketers Need</Link>
                            <Link to="/automotive-marketing/" className="list-group-item">Automotive PR &amp; Marketing</Link>
                            <div className="list-group">
                                <Link to="/automotive-marketing/auto-mindshare/" className="list-group-item">Auto Mindshare</Link>
                            </div>
                            <Link to="/healthcare-marketing/" className="list-group-item">Healthcare Marketing</Link>
                            <Link to="/hospitality-marketing/" className="list-group-item">Hospitality Marketing</Link>
                            <Link to="/outdoor-retail-marketing/" className="list-group-item">Outdoor Retail Marketing</Link>
                            <Link to="/strategic-alliances-press-release/" className="list-group-item">Strategic Alliances Press Release</Link>
                            <Link to="/technology-marketing/" className="list-group-item">Technology Marketing</Link>
                            <div className="list-group">
                                <Link to="/technology-marketing/desktop-as-a-service-marketing/" className="list-group-item">
                                    Desktop as a Service Marketing
                                </Link>
                                <Link to="/technology-marketing/strategic-alliances-2019-trends-infographic/" className="list-group-item">
                                    Strategic Alliances 2019 Trends
                                </Link>
                                <Link to="/technology-marketing/2024-channel-and-partner-marketing-trends-and-challenges/" className="list-group-item">
                                    2024 Channel and Partner Marketing Trends and Challenges
                                </Link>
                            </div>
                            <Link to="/the-thoughtful-band-harnessing-the-power-of-thought-leadership-in-shaping-brand-perceptions/" className="list-group-item">
                                The Thoughtful Brand: Harnessing the Power of Thought Leadership in Shaping Brand Perceptions
                            </Link>
                            <Link to="/microsoft-csp/" className="list-group-item">Microsoft CSP</Link>                            
                            <Link to="/microsoft-spla/" className="list-group-item">Microsoft SPLA Resellers and Hosters</Link>
                            <Link to="/careers/" className="list-group-item">Careers</Link>
                            <Link to="/metrix/" className="list-group-item">Metrix</Link>
                            <Link to="/legal/" className="list-group-item">Legal</Link>
                            <Link to="/cookies/" className="list-group-item">Cookies</Link>
                            <Link to="/reports/" className="list-group-item">Reports</Link>
                            <div className="list-group">
                                <Link to="/reports/latest-trends-preferences-in-online-education-options/" className="list-group-item">
                                    Latest Trends &amp; Preferences in Online Education Options
                                </Link>
                                <Link to="/reports/new-research-on-student-loan-debt-repayment-activity/" className="list-group-item">
                                    New Research On Student Loan Debt &amp; Repayment Activity
                                </Link>
                                <Link to="/reports/2016-merit-mile-research-and-concussion-perception-survey/" className="list-group-item">
                                    2016 Merit Mile Research and Concussion Perception Survey
                                </Link>
                                <Link to="/reports/trade-show-tips/" className="list-group-item">
                                    Trade Show Tips
                                </Link>
                            </div>
                            <Link to="/swag/" className="list-group-item">SWAG</Link>
                            <div className="list-group-item bold">
                                <Link to="/portfolio/">Portfolio</Link>
                            </div>
                            <div className="list-group">
                                <Link to="/portfolio/media/" className="list-group-item">Media</Link>
                                <div className="list-group">
                                    <Link to="/portfolio/media/advertising/" className="list-group-item">Advertising</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/media/advertising/hearst-black-book/" className="list-group-item">Hearst Black Book</Link>
                                        <Link to="/portfolio/media/advertising/equifax/" className="list-group-item">Equifax</Link>
                                        <Link to="/portfolio/media/advertising/protective/" className="list-group-item">Protective</Link>
                                    </div>
                                    <Link to="/portfolio/media/branding/" className="list-group-item">Branding</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/media/branding/boca-raton-historical-society/" className="list-group-item">Boca Raton Historical Society</Link>
                                        <Link to="/portfolio/media/branding/anywhereapp/" className="list-group-item">AnywhereApp</Link>
                                        <Link to="/portfolio/media/branding/boca-jets-lacrosse/" className="list-group-item">Boca Jets Lacrosse</Link>
                                        <Link to="/portfolio/media/branding/the-ledge-restaurant/" className="list-group-item">The Ledge Restaurant</Link>
                                    </div>
                                    <Link to="/portfolio/media/broadcast-video/" className="list-group-item">Broadcast &amp; Video</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/media/broadcast-video/t-systems-video/" className="list-group-item">T-Systems Video</Link>
                                        <Link to="/portfolio/media/broadcast-video/why-dxc-video/" className="list-group-item">Why DXC Video</Link>
                                        <Link to="/portfolio/media/broadcast-video/microsoft-ip-staircase-video/" className="list-group-item">Microsoft IP Staircase Video</Link>
                                    </div>
                                    <Link to="/portfolio/media/channel-marketing-sales-enablement/" className="list-group-item">Channel Marketing/Sales Enablement</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/media/channel-marketing-sales-enablement/microsoft-cognizant-ebook/" className="list-group-item">Microsoft Cognizant eBook</Link>
                                        <Link to="/portfolio/media/channel-marketing-sales-enablement/microsoft-nordcloud-ebook/" className="list-group-item">Microsoft Nordcloud eBook</Link>
                                        <Link to="/portfolio/media/channel-marketing-sales-enablement/citrix-google-ebook/" className="list-group-item">Citrix Google eBook</Link>
                                        <Link to="/portfolio/media/channel-marketing-sales-enablement/citrix-microsoft-ebook/" className="list-group-item">Citrix Microsoft eBook</Link>
                                    </div>
                                    <Link to="/portfolio/media/direct-mail/" className="list-group-item">Direct Mail</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/media/direct-mail/datacore/" className="list-group-item">Datacore</Link>
                                    </div>
                                    <Link to="/portfolio/media/infographics/" className="list-group-item">Infographics</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/media/infographics/outdoor-retail/" className="list-group-item">Outdoor Retail</Link>
                                        <Link to="/portfolio/media/infographics/kddi-america/" className="list-group-item">KDDI America</Link>
                                        <Link to="/portfolio/media/infographics/citrix-microsoft-teams/" className="list-group-item">Citrix Microsoft Teams</Link>
                                        <Link to="/portfolio/media/infographics/hitachi-solutions/" className="list-group-item">Hitachi Solutions</Link>
                                    </div>
                                    <Link to="/portfolio/media/interactive-web/" className="list-group-item">Interactive/Web</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/media/interactive-web/dxc-website/" className="list-group-item">DXC Website</Link>
                                        <Link to="/portfolio/media/interactive-web/lifesync-website/" className="list-group-item">LifeSync Website</Link>
                                        <Link to="/portfolio/media/interactive-web/softnas-website/" className="list-group-item">SoftNAS Website</Link>
                                    </div>
                                    <Link to="/portfolio/media/lead-generation/" className="list-group-item">Lead Generation</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/media/lead-generation/dxc-lead-generation/" className="list-group-item">DXC Lead Generation</Link>
                                        <Link to="/portfolio/media/lead-generation/jdl-lead-generation/" className="list-group-item">JDL Lead Generation</Link>
                                        <Link to="/portfolio/media/lead-generation/liveaction-lead-generation/" className="list-group-item">LiveAction Lead Generation</Link>
                                        <Link to="/portfolio/media/lead-generation/mdlive-lead-generation/" className="list-group-item">MDLIVE Lead Generation</Link>
                                    </div>
                                    <Link to="/portfolio/media/public-relations/" className="list-group-item">Public Relations</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/media/public-relations/compliancepoint/" className="list-group-item">CompliancePoint</Link>
                                        <Link to="/portfolio/media/public-relations/equifax/" className="list-group-item">Equifax</Link>
                                        <Link to="/portfolio/media/public-relations/kddia/" className="list-group-item">KDDIA</Link>
                                    </div>
                                    <Link to="/portfolio/media/research/" className="list-group-item">Research</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/media/research/microsoft/" className="list-group-item">Microsoft</Link>
                                        <Link to="/portfolio/media/research/hearst-black-book/" className="list-group-item">Hearst Black Book</Link>
                                        <Link to="/portfolio/media/research/automotive-mindshare/" className="list-group-item">Automotive Mindshare</Link>
                                    </div>
                                    <Link to="/portfolio/media/social-media/" className="list-group-item">Social Media</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/media/social-media/boca-jets-lacrosse/" className="list-group-item">Boca Jets Lacrosse</Link>
                                        <Link to="/portfolio/media/social-media/dxc/" className="list-group-item">DXC</Link>
                                        <Link to="/portfolio/media/social-media/equifax/" className="list-group-item">Equifax</Link>
                                    </div>
                                    <Link to="/portfolio/media/trade-shows-booth-designs/" className="list-group-item">Trade Show &amp; Booth Designs</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/media/trade-shows-booth-designs/citrix/" className="list-group-item">Citrix</Link>
                                        <Link to="/portfolio/media/trade-shows-booth-designs/fenix-auto-parts/" className="list-group-item">Fenix Auto Parts</Link>
                                        <Link to="/portfolio/media/trade-shows-booth-designs/microsoft/" className="list-group-item">Microsoft</Link>
                                    </div>
                                </div>
                                <Link to="/portfolio/industry/" className="list-group-item">Industry</Link>
                                <div className="list-group">
                                    <Link to="/portfolio/industry/hospitality/" className="list-group-item">Hospitality</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/industry/hospitality/abc-global-services/" className="list-group-item">ABC Global Services</Link>
                                        <Link to="/portfolio/industry/hospitality/the-ledge-restaurant/" className="list-group-item">The Ledge Restaurant</Link>
                                        <Link to="/portfolio/industry/hospitality/via-mizner/" className="list-group-item">Via Mizner</Link>
                                    </div>
                                    <Link to="/portfolio/industry/financial-services/" className="list-group-item">Financial Services</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/industry/financial-services/b2r-finance/" className="list-group-item">B2R Finance</Link>
                                        <Link to="/portfolio/industry/financial-services/dwell-finance/" className="list-group-item">Dwell Finance</Link>
                                    </div>
                                    <Link to="/portfolio/industry/technology/" className="list-group-item">Technology</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/industry/technology/dxc/" className="list-group-item">DXC</Link>
                                        <Link to="/portfolio/industry/technology/fujitsu/" className="list-group-item">Fujitsu</Link>
                                        <Link to="/portfolio/industry/technology/insight/" className="list-group-item">Insight</Link>
                                    </div>
                                    <Link to="/portfolio/industry/healthcare/" className="list-group-item">Healthcare</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/industry/healthcare/lifesync/" className="list-group-item">LifeSync</Link>
                                        <Link to="/portfolio/industry/healthcare/mdlive/" className="list-group-item">MDLIVE</Link>
                                    </div>
                                    <Link to="/portfolio/industry/consumer-products/" className="list-group-item">Consumer Products</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/industry/consumer-products/altier-jewelers/" className="list-group-item">Altier Jewelers</Link>
                                        <Link to="/portfolio/industry/consumer-products/quackskins/" className="list-group-item">Quackskins</Link>
                                    </div>
                                    <Link to="/portfolio/industry/automotive/" className="list-group-item">Automotive</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/industry/automotive/hearst-black-book/" className="list-group-item">Hearst Black Book</Link>
                                        <Link to="/portfolio/industry/automotive/motor/" className="list-group-item">MOTOR</Link>
                                        <Link to="/portfolio/industry/automotive/swapalease/" className="list-group-item">Swapalease</Link>
                                    </div>
                                    <Link to="/portfolio/industry/other-b2b/" className="list-group-item">Other-B2B</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/industry/other-b2b/boca-harbour-capital/" className="list-group-item">Boca Harbour Capital</Link>
                                        <Link to="/portfolio/industry/other-b2b/devicebits/" className="list-group-item">DeviceBits</Link>
                                        <Link to="/portfolio/industry/other-b2b/waiv/" className="list-group-item">WAIV</Link>
                                    </div>
                                    <Link to="/portfolio/industry/non-profit/" className="list-group-item">Non-Profit</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/industry/non-profit/boca-raton-historical-society/" className="list-group-item">Boca Raton Historical Society</Link>
                                        <Link to="/portfolio/industry/non-profit/swing-ding/" className="list-group-item">Swing Ding</Link>
                                        <Link to="/portfolio/industry/non-profit/cystic-fibrosis-foundation/" className="list-group-item">Cystic Fibrosis Foundation</Link>
                                    </div>
                                    <Link to="/portfolio/industry/other-b2c/" className="list-group-item">Other-B2C</Link>
                                    <div className="list-group">
                                        <Link to="/portfolio/industry/other-b2c/abc-global-services/" className="list-group-item">ABC Global Services</Link>
                                        <Link to="/portfolio/industry/other-b2c/laure-nell/" className="list-group-item">Laure Nell</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </article>
    </Layout>
)